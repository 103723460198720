import React from "react";
import { Layout, SEO, DefaultHeader, PortableText, Footer } from "components";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { motion } from "framer-motion";
import { mapEdgesToNodes, filterOutDocsPublishedInTheFuture } from "../lib/helpers";
import { Checkmark } from "assets/svgs";
import { FadeInUp } from "components";
import { H3, P } from "mixins";

export const query = graphql`
  query ServicesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    banner: file(relativePath: { eq: "assets/images/services-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    services: allSanityService(limit: 10, sort: { order: ASC, fields: publishedAt }) {
      edges {
        node {
          id
          title
          publishedAt
          brochure {
            brochureFile {
              asset {
                url
              }
            }
          }
          mainImage {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          serviceSvg
          bulletPoints
          orderedList
          _rawBody
        }
      }
    }
  }
`;

const ServicesPage = (props) => {
  const { data, errors } = props;
  const site = (data || {}).site;
  const banner = (data || {}).banner;
  const services = (data || {}).services
    ? mapEdgesToNodes(data.services).filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <SEO
        title="Our Services"
        description="LHS remains the most professional and technically innovative provider of Commercial Test House, Product and System Testing. We continually strive to ensure that safety first and quality always matches our client’s safety expectations as we continue to live our company motto, “Your Partners in Safety”"
      />
      <DefaultHeader
        title="LHS Services"
        description="LHS remains the most professional and technically innovative provider of Commercial Test House, Product and System Testing. We continually strive to ensure that safety first and quality always matches our client’s safety expectations as we continue to live our company motto, “Your Partners in Safety”"
        imageFluid={banner}
      />
      <Container>
        {services.map((node) => (
          <Service
            key={node.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            id={node.slug.current}
          >
            {node.mainImage && node.mainImage.asset && (
              <ImageContainer>
                <FadeInUp>
                  <SvgContainer dangerouslySetInnerHTML={{ __html: node.serviceSvg }} />
                  <Img fluid={node.mainImage.asset.fluid} alt="banner" />
                  {node.brochure.map((item, i) => (
                    <BrochureButton key={i} href={item.brochureFile.asset.url} target="blank">
                      Download Brochure
                    </BrochureButton>
                  ))}
                </FadeInUp>
              </ImageContainer>
            )}
            <Content>
              <FadeInUp>
                <Title>{node.title}</Title>
                <Text> {node._rawBody && <PortableText blocks={node._rawBody} />}</Text>
                <OrderedList>
                  {node.orderedList.map((point, i) => (
                    <OrderedListItem key={i}>{point}</OrderedListItem>
                  ))}
                </OrderedList>
                <BulletPoints>
                  {node.bulletPoints.map((point, i) => (
                    <BulletPoint key={i}>
                      <Checkmark />
                      {point}
                    </BulletPoint>
                  ))}
                </BulletPoints>
              </FadeInUp>
            </Content>
          </Service>
        ))}
      </Container>
      <Container>
        <SectionTitle>Other Services Provided</SectionTitle>
        <SectionP>
          For over 30 years now LHS has provided professional services to national and multinational
          companies, if you have any queries relating to our services, we would be happy to meet
          with you and bring a high level of technical and legal experience to the discussions.
        </SectionP>
        <AdditionalServices>
          <div>
            <div>
              <h4>Legal Investigation</h4>
              <ul>
                <li>Accident and Incident Investigation</li>
                <li>Product Investigation and Reporting</li>
                <li>Expert Witness for court</li>
              </ul>
            </div>
            <div>
              <h4>Consultation Services</h4>
              <ul>
                <li>Safety Management and Consultation Services </li>
                <li>Safe Operations at work S.I. No. 299</li>
                <li>Patient Handling Consultation Services</li>
                <li>CE Marking for Equipment and Machinery</li>
                <li>Risk Assessments and Method Statements</li>
                <li>Product Safety and Assessments</li>
                <li>Guide to Statutory Compliance</li>
                <li>Structural Engineering and Certificate of Compliance</li>
                <li>Project Management</li>
              </ul>
            </div>
            <div>
              <h4>Safety Training Services</h4>
              <ul>
                <li>Safe Slinging and Rigging</li>
                <li>Overhead Cranes</li>
                <li>Fall Protection – Working at Heights</li>
                <li>Basic Fire Safety Training for Office Staff </li>
                <li>Incident and Product Investigation</li>
                <li>Powered Pallet and Lift Truck Operations</li>
                <li>People Handling for Supported Living </li>
                <li>Patient Handling Equipment</li>
                <li>Manual Handling</li>
              </ul>
            </div>
            <div>
              <h4>Design Services</h4>
              <ul>
                <li>Mechanical and Structural Calculations</li>
                <li>CAD Design Drawings – 2D, 3D</li>
                <li>Computer Modelling and Simulations</li>
              </ul>
            </div>
            <div>
              <h4>Calibrations</h4>
              <ul>
                <li>Torque Wrenches from from 1 N·m to 2000 N·m.</li>
                <li>Torque Screwdrivers Adjustable and Fixed </li>
                <li>Manual and Electronic Torque Multipliers</li>
                <li>Evo Torque Battery Tools</li>
                <li>Pneumatic Torque Multipliers </li>
                <li>Mechanical and Electronic Torque Testers</li>
                <li>Torque Measurement Equipment</li>
                <li>Torque Transducers</li>
                <li>Ultrasonic Bolt Measurement</li>
                <li>Gas Detection Meters</li>
                <li>Confined Space Equipment</li>
                <li>Breathing Apparatus</li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h4>Inspection and Certification Services</h4>
              <ul>
                <li>Statutory Inspection and Certification</li>
                <li>Lifting and Safety Equipment</li>
                <li>Statutory Compliance Audit Inspection Services</li>
                <li>PPE, Safety and Rescue Equipment</li>
                <li>Air Receivers and Vessels Certification </li>
                <li>Pressure Equipment Inspection</li>
                <li>Lifejackets and Personal Buoyancy Equipment</li>
                <li>Pallet Racking & Storage Systems Inspection </li>
                <li>Portable Appliance Testing Industrial and Domestic</li>
                <li>Patient Handling Equipment Inspection</li>
                <li>Swimming Pool Lift to Transfer Disabled Persons </li>
                <li>Automatic Gates and Access Systems and Fire Doors</li>
                <li>Static Testing for Explosion Proof Zoned Area </li>
                <li>Fire Extinguishers Inspection</li>
                <li>Fuel Dispense/Pump Testing</li>
                <li>Sports Grounds and Adventure Equipment</li>
                <li>Personnel and Goods Lifts </li>
                <li>Mobile and Movable Jacks and Associated Equipment</li>
                <li>Skip and Container Waste Management Equipment</li>
                <li>Portable and Fixed Ladders and Scaffolding Equipment </li>
                <li>Work Equipment and Tools Including Electrical Tools</li>
                <li>Mobile Elevating Work Platforms </li>
                <li>Steel Static Storage and Support Systems </li>
                <li>Fixed and Mobile Machinery Including Diggers/Excavators</li>
                <li>Steel Overhead and Runway Beams </li>
                <li>Anchor and Holding Devices </li>
                <li>Truck Boom and HIAB Lifting Machinery</li>
                <li>Water Treatment Lifting Equipment and Derricks </li>
                <li>Mobile and Fixed Workstation Equipment</li>
                <li>Vehicle Lifting Tables and Scissors Lifts</li>
                <li>Roll-Over Protective Structures (ROPS)</li>
                <li>Transportation Handling Equipment</li>
                <li>COVID 19 Home and Office Regularity Inspections</li>
              </ul>
            </div>
            <div>
              <h4>Load Testing Services</h4>
              <ul>
                <li>Load Testing of Safety Equipment and Machines </li>
                <li>Load Testing of Structures and Buildings</li>
                <li>Standard Load Cells with accuracy +/- 0,2 %</li>
                <li>Test House Load Cell with accuracy +/- 0.03% </li>
                <li>Torque Load Cell accuracy+/- 0.15% Rated Capacity</li>
                <li>Displacement Sensors with accuracy +/- 0,01 % </li>
                <li>Laser Total Station Leica TC407 Laser Precision Rotary Laser</li>
                <li>Steel Hand Weights up to 3000 kg </li>
                <li>Concrete and Steel Weights up to 50,000 Kg </li>
                <li>Water Weights up to 50,000 Kg</li>
              </ul>
            </div>
          </div>
        </AdditionalServices>
      </Container>
      <Footer />
    </Layout>
  );
};

const SectionP = styled(P)`
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
`;

const SectionTitle = styled(H3)`
  text-align: center;
  margin-top: 0;
  @media (min-width: 768px) {
    grid-column: 1/3;
  }
  @media (min-width: 1150px) {
    grid-column: 1/4;
  }
`;

const AdditionalServices = styled.div`
  grid-column: 1/7;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 3rem auto;
  h4 {
    color: #f94c00;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  ul {
    padding: 0.5rem 1rem;
    li {
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 0.5rem;
      line-height: 1.5em;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column: 2/12;
  }
`;

const BrochureButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  line-height: 1em;
  position: absolute;
  left: 0;
  bottom: -5rem;
  border-radius: 6px;
  height: 3rem;
  width: auto;
  padding: 0.75rem 1.5rem;
  background: #f94c00;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.75;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;

const OrderedListItem = styled.li`
  color: #232528;
  position: relative;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  counter-increment: my-awesome-counter;

  &:before {
    content: counter(my-awesome-counter);
    background: #f94c00;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: -2rem;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    margin-top: 0.25rem;
  }
`;

const OrderedList = styled.ol`
  width: 100%;
  list-style: none;
`;

const BulletPoint = styled.li`
  color: #f94c00;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  svg {
    width: 0.875rem;
    position: absolute;
    top: 0.425rem;
    left: -2rem;
  }
`;

const BulletPoints = styled.ul`
  list-style: none;
  width: 100%;
`;

const Text = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 300;
    color: black;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: 0;
  color: #243f93;
`;

const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  @media (min-width: 768px) {
    grid-column: 4/7;
  }
  @media (min-width: 1150px) {
    grid-column: 7/12;
  }
`;

const SvgContainer = styled.div`
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  padding: 2rem;
  border-radius: 0.425rem;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  background: #eaf6ff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 5rem;
    width: 5rem;
    max-width: 80%;
    max-height: 80%;
  }
`;

const ImageContainer = styled.div`
  grid-area: image;
  position: relative;
  width: 100%;
  padding-bottom: 8rem;
  > div {
    height: auto;
    width: 100%;
    position: relative;
  }
  .gatsby-image-wrapper {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    img {
      object-fit: cover;
    }
  }
  @media (min-width: 768px) {
    grid-column: 1/4;
  }
  @media (min-width: 1150px) {
    grid-column: 2/6;
  }
`;

const Service = styled(motion.div)`
  width: 100%;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  grid-template-areas: "image" "content";
  @media (min-width: 768px) {
    padding: 2rem;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(6, 1fr);

    grid-template-areas: "image content";
    &:nth-child(even) {
      grid-template-areas: "content image";
    }
    &:nth-child(even) ${ImageContainer} {
      grid-column: 4/7;
    }
    &:nth-child(even) ${Content} {
      grid-column: 1/4;
    }
  }
  @media (min-width: 1150px) {
    padding: 0;
    grid-column-gap: 0rem;

    grid-template-areas: "image content";
    grid-template-columns: repeat(12, 1fr);
    &:nth-child(even) {
      grid-template-areas: "content image";
    }
    &:nth-child(even) ${ImageContainer} {
      grid-column: 8/12;
    }
    &:nth-child(even) ${Content} {
      grid-column: 2/7;
    }
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export default ServicesPage;
